import request from '../util/request'

// 新闻模块-分页列表
export function graphic(params) {
    return request({
      url: '/website/graphic/page',
      method: 'get',
      params
    })
}

// 新闻模块--获取文章新闻信息
export function graphicdetail(graphicId) {
  return request({
    url: '/website/graphic/detail/'+ graphicId,
    method: 'get'
  })
}

// 新闻模块--文章类型列表
export function graphiclist() {
  return request({
    url: '/website/graphic/find',
    method: 'get'
  })
}
