<template>
<div class="navmenu-main">
  <div class="navmenu-top">
    <div class="navmenu-logo">
      <img src="../assets/navmenu-logo.png">
    </div>
    <el-menu :default-active="this.$route.path" router class="el-menu-demo" mode="horizontal" @select="handleSelect">
      <el-menu-item index="/">首页</el-menu-item>
      <el-submenu index="1">
        <template slot="title">产品介绍</template>
        <el-menu-item class="navmenu-list" index="/xct">薪财通</el-menu-item>
        <el-menu-item class="navmenu-list" index="/xzt">薪资条</el-menu-item>
        <el-menu-item class="navmenu-list" index="/zpy">招聘云</el-menu-item>
        <el-menu-item class="navmenu-list" index="/yggl">员工管理</el-menu-item>
        <el-menu-item class="navmenu-list" index="/lgpt">灵工平台</el-menu-item>
      </el-submenu>
      <el-menu-item index="/service">生态服务</el-menu-item>
      <el-menu-item index="/aboutus">关于我们</el-menu-item>
      <el-submenu index="2">
        <template slot="title">新闻动态</template>
        <el-menu-item class="navmenu-list" index="/mtbd">媒体报道</el-menu-item>
        <el-menu-item class="navmenu-list" index="/cpdt">产品动态</el-menu-item>
        <el-menu-item class="navmenu-list" index="/hyzx">行业资讯</el-menu-item>
      </el-submenu>
      <el-menu-item index="/contactus">联系我们</el-menu-item>
      <button class="navmenu-login" @click="zcdllink">注册 / 登录</button>
    </el-menu>
    <div class="clear"></div>
  </div>
</div>  
</template>

<script>
  export default {
    name:"navhd",
    data() {
      return {
      };
    },
    methods: {
      handleSelect(key, keyPath) {
      },
      zcdllink(url) {
        window.open('http://zxyl.zhxtech.cn/company/#/web', '_blank')
      }
    }
  }
</script>

<style lang="scss" scoped>
.navmenu-main{
  width: 100%;
  background-color: #ffffff;
}
.navmenu-top{
  width: 900px;
  height: 80px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

}
.navmenu-logo{
  float: left;
  height: 40px;
  padding: 20px 0px;
}
.navmenu-logo img{
    height: 100%;
}
.el-menu{
  float: right;
}
.el-menu.el-menu--horizontal{
  border  : 0;
}
.el-menu--horizontal>.el-menu-item{
  height: 80px;
  line-height: 80px;
  color:#000000;
  font-size: 16px;
}

.el-submenu{
  height: 80px;
}

::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title{
  height: 80px;
  line-height: 80px;
  color:#000000;
}
.el-menu--horizontal > .el-menu-item.is-active:after{
  content: " ";
  position: absolute;
  margin-top: 60px;
  margin-left: -38%;
  height: 5px;
  width: 17px;
  background-color: #ffb700;
  border-radius: 50px;
}
.navmenu-login{
  background-color: #2a69f6;
  border-radius: 50px;
  color:#fff;
  height: 30px;
  line-height: 30px;
  border: 0;
  padding: 0 20px;
  margin-top: 25px;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
  color: #2a69f6;
}
.el-menu--horizontal > .el-menu-item.is-active{
  border-bottom: 0px;
  color: #2a69f6;
}
.el-menu--horizontal > .el-menu-item{
  border-bottom: 0px;
}
.el-menu--popup-bottom-start{
  margin-top: 0px;
}
.el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title{
  color: #333333;
  text-align: center;
  background: none;
  // width: 116px;
}

</style>

<style>
.clear{
  clear: both;
}
.el-menu--collapse .el-menu .el-submenu, .el-menu--popup{
  min-width: 116px !important;
}
.el-menu--popup{
  border-radius: 0 !important;
  padding: 0 !important;
}
.el-menu--popup-bottom-start{
  margin-top: 0px !important;
}
.el-menu--horizontal .el-menu .el-menu-item:hover, .el-menu--horizontal .el-menu .el-submenu__title:hover{
  background-color: #ffb700 !important;
  color: #fff !important;
}
.el-menu-item{
  transition: border-color .1s,background-color .1s,color .1s !important;
}
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title{
  border-bottom: 0!important;
}
.el-submenu__title{
  font-size: 16px!important;
}
</style>
