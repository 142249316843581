<template>
    <div class="footer">
      <div class="footer-bg">
        <div class="footer-main">
          <div class="footer-xx">
            <div class="footer-xxbt">企业邮箱</div>
            <div>rczj@rczjzb.cn</div>
          </div>
          <div class="footer-xx">
            <div class="footer-xxbt">客服热线</div>
            <div>400-608-0706<br/>工作时间：08:30~17:30</div>
          </div>
          <div class="footer-xx">
            <div class="footer-xxbt">公司地址</div>
            <div class="footer-xxdz">江苏省无锡市新吴区中国传感网国际创新园A栋</div>
          </div>
          <div class="footer-xx">
            <img src="../assets/footer-ewm.png">
          </div>
        </div>
        <div class="footer-text">Copyright©2021 zxyl.zhxtech.cn 无锡掌薪网络科技有限公司 版权所有 苏ICP备19034323号-1</div>
        </div>
    </div>
    
</template>

<script>
  export default {
    name:"footerhd",
    data() {
      return {

      };
    },
    methods: {
      handleSelect(key, keyPath) {
      }
    }
  }
</script>

<style lang="scss" scoped>
.footer{
  background: #fff;
  height: 350px;
  position: relative;
}
.footer-bg{
  height: 350px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center top;
  overflow-x: hidden;
  background-image: url(../assets/footer-bg.png);
}
@media (max-width:900px) {
	.footer-bg{width: 900px;}
}
.footer-main{
  height: 230px;
  width: 900px;
  line-height: 36px;
  margin: 60px auto 0 auto;
  color: #ffffff;
}
.footer-text{
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  color:#ffffff;
}
.footer-xx{
  width: 225px;
  float: left;
}
.footer-xx img{
  float: right;
  height: 160px;

}
.footer-xxbt{
  font-size: 26px;
  margin-bottom: 10px;
  margin-top: 20px;
}

</style>