<template>
  <div class="home-main">
    <!-- 顶部导航 -->
    <navhd></navhd>
    <!-- 滚动banner图 -->
    <el-carousel  height="450px"  trigger="click">
      <el-carousel-item v-for="item in images" :key="item.id">
        <img :src="item.graphicLogo"/>
      </el-carousel-item>
    </el-carousel>
    <!-- 产品介绍 -->
    <div class="bg-white">
      <div class="js-main">
        <div class="js-btbg">Product introduction</div>
        <div class="js-bt"><span>产品</span>介绍</div>
        <div class="js-cplist">
          <ul>
            <li>
              <div class="js-cpbt">薪财通</div>
              <div class="js-cppic xct"></div>
              <router-link to='/xct'>
              <button type="button" class="js-cpbtn">查看详情</button>
              </router-link>
            </li>
            <li>
              <div class="js-cpbt">薪资条</div>
              <div class="js-cppic xzt"></div>
              <router-link to='/xzt'>
              <button type="button" class="js-cpbtn">查看详情</button>
              </router-link>
            </li>
            <li>
              <div class="js-cpbt">招聘云</div>
              <div class="js-cppic zpy"></div>
              <router-link to='/zpy'>
              <button type="button" class="js-cpbtn">查看详情</button>
              </router-link>
            </li>
            <li>
              <div class="js-cpbt">员工管理<span>(数字化)</span></div>
              <div class="js-cppic yggl"></div>
              <router-link to='/yggl'>
              <button type="button" class="js-cpbtn">查看详情</button>
              </router-link>
            </li>
            <li>
              <div class="js-cpbt">灵工平台</div>
              <div class="js-cppic lgpt"></div>
              <router-link to='/lgpt'>
              <button type="button" class="js-cpbtn">查看详情</button>
              </router-link>
            </li>
          </ul>
          <div class="clear"></div>
        </div>
      </div>
    </div>
    <!-- 生态服务 -->
    <div class="bg-bule">
      <div class="js-main">
        <div class="js-btbg">Ecological services</div>
        <div class="js-bt"><span>生态</span>服务<p>(互联服务)</p></div>
        <div class="js-cplist">
          <template>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="定制企业公众号" name="first">
                <div class="st-main">
                  <div class="st-left"><img src="../assets/stfw/st-dz.png"/></div>
                  <div class="st-right">
                    我们可根据企业自身定位；<br/>
                    为您定制符合企业形象的专属私有服务号；<br/>
                    您可在服务号展示公司历史、文化及产品、服务；<br/>
                    聚合各类办公系统，实现智能化、现代化管理；<br/>
                    直接触达员工手机终端，提升员工参与度及满意度；<br/>
                    有利于快速传播企业文化，提高员工凝聚力。
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="公众号代运营" name="second">
                <div class="st-main">
                  <div class="st-left"><img src="../assets/stfw/st-dyy.png"/></div>
                  <div class="st-right">
                    没有才思泉涌，不会拍照；有了服务号，反而增加了负担？<br/>
                    别担心，我们将为您跟拍摄影、摄像<br/>
                    为您实时完成新闻报道，产品包装推文<br/>
                    确保您的公众号时尚、现代、科技
                  </div>
                  <div class="clear"></div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="商业保险" name="third">
                <div class="st-main">
                  <div class="st-left"><img src="../assets/stfw/st-bx.png"/></div>
                  <div class="st-right">
                    对接平安、太平洋、阳光、人保多家知名保险公司<br/>
                    年单、月单自由选择<br/>
                    随时加减保减少成本浪费
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="背景调查+电子合同" name="fourth">
                <div class="st-main">
                  <div class="st-left"><img src="../assets/stfw/st-bd.png"/></div>
                  <div class="st-right">
                    一键身份背调，避免引狼入室<br/>
                    无纸化签署，便于存档及管理
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </template>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="bg-white">
      <div class="js-main">
        <div class="js-btbg">Cooperative partner</div>
        <div class="js-bt"><span>合作</span>伙伴</div>
        <div class="js-hzlist">
          <logoshd></logoshd>
          <div class="clear"></div>
        </div>
      </div>
    </div>
    <!-- 底部信息 -->
    <footerhd></footerhd>
  </div>
</template>

<script>
import navhd from '../components/Navmenu'
import footerhd from '../components/Footer'
import logoshd from '../components/Logos'
import {graphic} from '../api/graphic' 
export default {
  components:{
    [navhd.name]:navhd,
    [footerhd.name]:footerhd,
    [logoshd.name]:logoshd
  },
  data() {
      return{
        activeName: 'first',
        images:[],
        query:{
          pageCurrent:1,
          pageSize:10,
          queryName:'',
          typeId:1
        }
     }
  },

  mounted(){
    graphic(this.query).then((res) =>{
      this.images = res.data.records
    })
  },
  methods: {
    handleClick(tab, event) {
    }
  }
}
</script>

<style  lang="scss" scoped>
  .clear{clear: both;}
  
  .bg-white{
    background-color: #fff;
    padding: 40px 0;
  }
  .bg-bule{
    background: url(../assets/stfw-bg.png) center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 40px 0;
  }
  
  .js-main{
    width: 1070px;
    margin:0px auto;
  }
  .js-btbg{
    color: #ececec;
    font-size: 35px;
    font-weight: bold;
    text-align: center;
  }
  .bg-bule .js-btbg{
    color: #e2e9fc;
    font-size: 35px;
    font-weight: bold;
    text-align: center;
  }
  .js-bt{
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-top: -20px;
    margin-bottom: 40px;
  }
  .js-bt span{
    color:#2a69f6;
  }
  .js-bt p{
    display: inline-block;
    position: absolute;
    margin: 0;
    line-height: 40px;
    font-size: 16px;
    color:#666;
  }
  .js-cplist ul li{
    width: 164px;
    height: 164px;
    padding: 20px 0px;
    margin-right: 20px;
    text-align: center;
    border-radius: 10px;
    float: left;
  }
  .js-cplist ul li:last-child{
    margin-right: 0px;
  }
   .js-cplist ul li:hover{
     box-shadow: 0px 1px 10px #ddd;
     margin-top: -10px;
   }
  .js-cpbt{
    margin-bottom: 20px;
    font-size: 16px;
  }
  .js-cpbt span{
    font-size: 12px;
    color:#666;
    display:block;
    position:relative;
    margin-bottom: -14px;
  }
  .js-cppic{
    width: 80px;
    height: 80px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom: 10px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .js-cplist ul li:hover .js-cppic{
      width: 80px;
      height: 80px;
      margin-left:auto;
      margin-right:auto;
      margin-bottom: 15px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
  }
  .xct{
    background-image: url(../assets/cpjs/cp-xct01.png);
  }
  .js-cplist ul li:hover .xct{
    background-image: url(../assets/cpjs/cp-xct02.png);
  }
  .xzt{
    background-image: url(../assets/cpjs/cp-xzt01.png);
  }
  .js-cplist ul li:hover .xzt{
    background-image: url(../assets/cpjs/cp-xzt02.png);
  }
  .zpy{
    background-image: url(../assets/cpjs/cp-zpy01.png);
  }
  .js-cplist ul li:hover .zpy{
    background-image: url(../assets/cpjs/cp-zpy02.png);
  }
  .yggl{
    background-image: url(../assets/cpjs/cp-yggl01.png);
  }
  .js-cplist ul li:hover .yggl{
    background-image: url(../assets/cpjs/cp-yggl02.png);
  }
  .lgpt{
    background-image: url(../assets/cpjs/cp-lgpt01.png);
  }
  .js-cplist ul li:hover .lgpt{
    background-image: url(../assets/cpjs/cp-lgpt02.png);
  }
    
  .js-cpbtn{
    display: none;
  }
  .js-cplist ul li:hover .js-cpbtn{
    display:inline-block;
    padding: 5px 10px;
    font-size: 12px;
    color:#fff;
    background-color: #ffb700;
    border-radius: 50px;
    border:0;
  }
  .st-main{
    padding: 40px 0px;
    height: 240px;
  }
  .st-left{ 
    float: left; 
    width: 320px;
    height: 250px;
    margin-right: 130px;
  }
  .st-left img{
    height: 100%;
  }
  .st-right{ 
    float: left; 
    width: 450px;
    line-height: 35px;
  }
  .js-hzlist ul li{
    width: 115px;
    height: 115px;
    margin: 15px;
    float: left;
  }
  .js-hzlist ul li img{
    width: 100%;
    height: 100%;
  }
  .el-carousel__container img{ width: 100%; height: 100%;}
</style>

<style lang="scss">
@media (max-width:900px) {
  .home-main{width: 900px;}
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
.el-tabs__nav{
    width: 864px;
    padding: 0px 18px;
  }
  .el-tabs__item.is-active,.el-tabs__item.is-active:hover{
    color: #ffffff!important;
    background-color: #2a69f6;
  }
  .el-tabs__item:hover{
    color: #2a69f6!important;
    cursor: pointer;
  }
  .el-tabs__item{
    width: 180px;
    margin: 0px 18px;
    padding: 0;
    text-align: center;
    border-radius: 50px;
    background-color: #fff;
  }
  
  .el-tabs__nav-wrap::after{
    display: none;
  }
  .el-tabs__active-bar {
    display: none;
}
  .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2), .el-tabs--bottom .el-tabs__item.is-top:nth-child(2), .el-tabs--top .el-tabs__item.is-bottom:nth-child(2), .el-tabs--top .el-tabs__item.is-top:nth-child(2){
    padding-right: 0;
    padding-left: 0;
  }
  .el-tabs--bottom .el-tabs__item.is-bottom:last-child, .el-tabs--bottom .el-tabs__item.is-top:last-child, .el-tabs--top .el-tabs__item.is-bottom:last-child, .el-tabs--top .el-tabs__item.is-top:last-child{
    padding-right: 0;
    padding-left: 0;
  }
  
</style>
