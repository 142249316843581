import request from '../util/request'

// 合作伙伴模块-分页列表
export function partner() {
    return request({
      url: '/website/partner/find',
      method: 'get'
    })
}

// test
export function partnerList(params) {
  return request({
    url: '/website/partner/page',
    method: 'get',
    params
  })
}