<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data(){
    return{
      
    }
  },
  methods:{
    
  },
  components:{

  },
  mounted(){

  }
}
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'microsoft yahei',Verdana,Arial,Helvetica,sans-serif;
  background-color: #f5f5f5;
}

ul{
  padding: 0;
  margin: 0;
  li{
    list-style: none;
  }
}
</style>
