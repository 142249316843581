<template>
  <div class="cases">
    <div class="jiantou">
    <div class="prev" v-if="currentPage">
        <img src="../assets/左.png"   @click="arrowClick('left')" />
      </div></div>
    <div class="icons">     
      <el-carousel :autoplay="false" arrow="never" indicator-position="none" ref="cardShow" >
        <el-carousel-item v-for="itemPage in pageLength" :key="itemPage">
          <div v-for="(item, index) in iconsList" :key="index"  class="icons-item">
            <img :src="item.partnerLogo" :title="item.partnerName">
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

<div class="jiantou1">
    <div class="next" v-if="pageLength > currentPage + 1">
        <img src="../assets/右.png" @click="arrowClick('right')" />
      </div>
</div>

  </div>
</template>


<script>
import { swiper, swiperSlide } from "vue-awesome-swiper"
import 'swiper/css/swiper.css'
import {partner,partnerList} from '../api/partner'
export default {
  name:"logoshd",
  data(){
    return {
      iconsList:[],
      query:{
        pageCurrent:1,
        pageSize:12,
        queryName:''
      },
      pageLength:1,
      currentPage:'',
    }

  },
  mounted(){
    partnerList(this.query).then((res)=>{
      this.iconsList = res.data.records
      this.pageLength = res.data.totalPages
      //this.currentPage = this.query.pageCurrent-1
    })
  },
  methods:{
    arrowClick(val) {
      if(val === 'right') {
        this.query.pageCurrent++  
        partnerList(this.query).then((res)=>{
          this.iconsList = res.data.records 
          this.currentPage = this.query.pageCurrent-1 
        })
      } else {
        this.query.pageCurrent-- 
        partnerList(this.query).then((res)=>{
          this.iconsList = res.data.records  
          this.currentPage = this.query.pageCurrent-1
        })
      }
    },

  },

  computed:{
  }
  
}
</script>

<style scoped lang="scss">
.cases {
  max-width: 1070px;
  height: 300px;
  margin: 0 auto;
}
.icons{
  width: 900px;
  float: left;
  background-color: #fff;
}
.jiantou{
  width: 80px;
  height: 300px;
  float: left;
}
.jiantou1{
  width: 80px;
  height: 300px;
  float: left;
}
.icons-item{
  width: 150px!important;
  height: 150px!important;
  padding-bottom: 0!important;
  float: left;
}
.icons-item img{
  width:100px;
  height: 100px;
  display: block;
  margin: 0 auto;
  padding-top: .2rem;
}
.icons-item p{
  margin-top: .1rem;
  font-size: 16px;
  text-align: center;
  color: #212121;
}

::v-deep .el-carousel__item:nth-child(2n+1) {
    background-color: #fff;
}

.prev{
  background-color:rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-top: 100px;
  img{
    width:20px;
    height: 33px;
    margin-top: 8px;
    margin-left: 13px;
  }
}

.next{
  background-color:rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-top: 100px;
  img{
    width:20px;
    height: 33px;
    margin-top: 8px;
    margin-left: 15px;
  }
}



</style>