import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { title: '首页'},
    component: Home
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    meta: { title: '关于我们'},
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Aboutus.vue')
    }
  },
  {
    path: '/service',
    name: 'service',
    meta: { title: '生态服务'},
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Service.vue')
    }
  },
  {
    path: '/contactus',
    name: 'contactus',
    meta: { title: '联系我们'},
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Contactus.vue')
    }
  },
  {
    path: '/xct',
    name: 'xct',
    meta: { title: '薪财通'},
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Xct.vue')
    }
  },
  {
    path: '/xzt',
    name: 'xzt',
    meta: { title: '薪资条'},
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Xzt.vue')
    }
  }
  ,
  {
    path: '/zpy',
    name: 'zpy',
    meta: { title: '招聘云'},
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Zpy.vue')
    }
  },
  {
    path: '/yggl',
    name: 'yggl',
    meta: { title: '员工管理'},
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Yggl.vue')
    }
  },
  {
    path: '/lgpt',
    name: 'lgpt',
    meta: { title: '灵工平台'},
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Lgpt.vue')
    }
  },
  {
    path: '/mtbd',
    name: 'mtbd',
    meta: { title: '媒体报道'},
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Mtbd.vue')
    }
  },
  {
    path: '/cpdt',
    name: 'cpdt',
    meta: { title: '产品动态'},
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Cpdt.vue')
    }
  },
  {
    path: '/hyzx',
    name: 'hyzx',
    meta: { title: '行业资讯'},
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Hyzx.vue')
    }
  },
  {
    path: '/xwxq/:id',
    name: 'xwxq',
    meta: { title: '新闻详情'},
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Xwxq.vue')
    }
  },
  {
    path: '/lunbo',
    name: 'lunbo',
    meta: { title: '轮播图标'},
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Lunbo.vue')
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
