import axios from 'axios'

// create an axios instance
const service = axios.create({
  baseURL:'http://zxyl.zhxtech.cn/cloud',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    // if (res.data.code !== 200) {  
    //    return Promise.reject(new Error(res.data.msg || 'Error'))
    // } 
    if( res.data.code=== 404){
      alert(res.data.msg || 'Error')
      //return Promise.reject(new Error(res.data.msg || 'Error'))
    }
    else if( res.data.code === 500){
      alert(res.data.msg || 'Error')
      // return Promise.reject(new Error(res.data.msg || 'Error'))
    }
    else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service

