import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';



Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueSwiper);

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app');

